@font-face {
  font-family: "TitanOne";
  font-display: swap;
  src: local("TitanOne"),
    url("./fonts/Titan_One/TitanOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LeagueGothic";
  font-display: swap;

  src: local("LeagueGothic"),
    url("./fonts/League_Gothic/static/LeagueGothic-Regular.ttf")
      format("truetype");
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "TitanOne", "Helvetica", "sans-serif";
  font-size: 65px;
  color: #ff0054;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 40px;
  }
}

h2 {
  font-family: "TitanOne", "Helvetica", "sans-serif";
  font-size: 55px;
  color: #ffadc9;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 40px;
  }
}

h3 {
  font-family: "LeagueGothic", "Helvetica", "sans-serif";
  font-size: 40px;
  color: #000;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 35px;
  }
}

p {
  font-family: "LeagueGothic", "sans-serif";
  font-size: 30px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media (max-width: 768px) {
    font-size: 25px;
  }
}
